import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

function BlogSideBar({ categories, recentPost }) {
  return (
    <>
      <div className="col-md-3 col-sm-12">
        <div className="sidebar-area">
          <div className="cate-box">
            <span className="title">Categories</span>
            <ul>
              {categories.map((category, index) => {
                return (
                  <li key={index}>
                    <Link to={"/blog/" + category.slug}>
                      <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                      <div>{category.name}</div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="recent-post-area">
            <span className="title"> Recent Post</span>
            <ul className="news-post">
              {recentPost.map((post, index) => {
                return (
                  <li key={index}>
                    <Link to={"/blog/" + post.slug} className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
                        <div className="item-post">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 paddimg-right-none">
                              <GatsbyImage
                                image={getImage(
                                  post.featuredImage?.node?.localFile
                                )}
                              />
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                              <h4>
                                <a href="blog-single.html">{post.title}</a>
                              </h4>
                              <span className="date">
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                ></i>{" "}
                                {post.date}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogSideBar
