import { Link, graphql } from "gatsby"
import React from "react"
import "./index.scss"
const PostPreview = ({ prev = null, next = null, ...props }) => {
  if (!prev && !next) {
    return null
  }

  return (
    <nav
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      {...props}
    >
      <div>
        {prev && (
          <Link to={"/blog/" + prev.slug} rel="prev">
            {" "}
            ← Last Post {console.log("props", props)}
          </Link>
        )}
      </div>

      <div style={{ justifySelf: "flex-end" }}>
        {next && (
          <Link to={"/blog/" + next.slug} rel="next">
            {" "}
            Next Post →{" "}
          </Link>
        )}
      </div>
    </nav>
  )
}

export default PostPreview
