import React, {useEffect} from "react"
import { graphql, Link } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import InnerPageHero from "../components/inner-page-hero/inner-page-hero"
import BlogSideBar from "../components/Blog/BlogSideBar/BlogSideBar"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PostPreview from "../components/PostPreview/PostPreview"
//Implementation of Share Buttons Component
import { Facebook, Twitter, Linkedin } from 'react-feather';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

function MatchReportsSingle({ data, pageContext }) {
  const imageData = data?.wp?.siteSettings?.siteSettings?.globalInnerHero
  const postData = data.wpPost
  const featuredImage = postData?.featuredImage?.node?.localFile
  const { prev, next } = pageContext
  
  //Social Sharing URL
  const url = typeof window !== 'undefined' ? window.location.href : ''

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh();
  }, []);

  return (
    <DefaultLayout data={data}>
      <InnerPageHero />

      <div className="rs-breadcrumbs sec-color">
        <GatsbyImage
          image={getImage(imageData?.backgroundImage?.localFile)}
          alt={"Saigon Raiders Club"}
          className="inner-img"
        />
        <div className="breadcrumbs-inner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center"
               data-aos="fade-up"
               data-aos-offset="100"
               data-aos-delay="5"
              >
                <h1 className="page-title">{postData.title}</h1>
                <ul>
                  <li>
                    <a className="active" href="/blog">
                      News Home &#187;{" "}
                    </a>
                  </li>
                  <li> News</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="single-blog-details sec-spacer">
        <div className="container">
          <div className="row">
            {/* <BlogContent featauredImage={featuredImage} content={postData?.content} /> */}

            <div className="col-md-8 col-sm-12">
              <div className="single-image">
                {featuredImage ? (
                  <GatsbyImage
                    className="img-champion"
                    image={getImage(featuredImage)}
                    alt={""}
                  />
                ) : null}
              </div>

              <div>
                {/* <h2>{`Venue: ${postData.venue}`}</h2>
                <h3>{`Time: ${postData.time}`}</h3>
                <h3>{`Score: ${postData.score}`}</h3> */}
              </div>

              <div dangerouslySetInnerHTML={{ __html: postData?.content }} />
              <div className="share-section">
                <div className="row">
                  <div className="col-sm-6 col-xs-12 life-style">
                    <span className="author">
                      <a href="#">
                        <i className="fa fa-user-o" aria-hidden="true"></i>{" "}
                        {postData?.author?.node?.name}
                      </a>
                    </span>
                    <span className="date">
                      <i className="fa fa-calendar" aria-hidden="true"></i>{" "}
                      {postData.date}
                    </span>
                    <span className="cat">
                      {postData?.categories?.nodes.map((category, index) => {
                        return (
                          <Link key={index} to={"/blog/" + category.slug}>
                            <i className="fa fa-folder-o" aria-hidden="true"></i>{" "}
                            {category.name}
                          </Link>
                        )
                      })}
                    </span>
                  </div>
                </div>
              </div>
              <div className="share-section2">
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <span> You Can Share It : </span>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <ul className="share-link">
                      <li>
                        <a>
                        <FacebookShareButton 
                          url={url} 
                          title={postData?.title} 
                        >
                          <Facebook strokeWidth={1.25} />
                        </FacebookShareButton>
                        </a>
                      </li>
                      <li>
                        <a>
                        <LinkedinShareButton url={url} title={postData?.title} >
                          <Linkedin strokeWidth={1.25} />
                        </LinkedinShareButton>
                        </a>
                      </li>
                      <li>
                        <a>
                        <TwitterShareButton url={url} title={postData?.title} >
                          <Twitter strokeWidth={1.25} />
                        </TwitterShareButton>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <PostPreview prev={prev} next={next} />
            </div>

            <BlogSideBar
              categories={postData?.categories?.nodes}
              recentPost={data.allWpPost?.nodes}
            />
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
export const query = graphql`
  query ($slug: String!) {
    wp {
      siteSettings {
        siteSettings {
          globalInnerHero {
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, height: 380)
                }
              }
            }
          }
        }
      }
    }
    wpPost(slug: { eq: $slug }) {
      id
      title
      content
      slug
      excerpt
      date(formatString: "MMM DD, YYYY")
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allWpPost(
      filter: { slug: { ne: $slug } }
      sort: { fields: [date], order: DESC }
      skip: 1
      limit: 3
    ) {
      nodes {
        title
        slug
        date(formatString: "MMM DD, YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
export default MatchReportsSingle
